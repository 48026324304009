<template>
  <div id="register-form" class="content">
    <div class="container-fluid">
      <section id="registration">
        <div class="form-content">
          <form ref="registrationForm" class="needs-validation" :class="form_class" novalidate @keyup.enter="submit">
            <h3 class="text-center">Registrati</h3>
            <div class="form-group">
              <label for="email">Email *</label>
              <input :disabled="isWaiting == true" type="email" name="email" required id="email" v-model="email" class="form-control">
              <div class="invalid-feedback">
                Inserisci un'indirizzo email valido.
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password *</label>
              <input :disabled="isWaiting == true" type="password" name="password" required id="password" minlength="8" maxlength="100" v-model="password" class="form-control">
              <div class="invalid-feedback">
                Inserisci una password lunga almeno 8 caratteri.
              </div>
            </div>
            <div class="form-group button-group">
              <button :disabled="isWaiting == true" class="btn btn-primary" @click="submit">Registrati</button>
            </div>
          </form>
        </div>
        <div class="register text-center">
          <p>Sei già registrato? <a @click="$emit('change', 'login')">Clicca qui</a></p>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

export default {
  data () {
    return {
      form_class: null,
      email: null,
      isWaiting: false,
      password: null
    }
  },
  methods: {
    submit (evt) {
      evt.preventDefault();

      let form = this.$refs['registrationForm']
      if (form.checkValidity() === false) {
        this.form_class = 'was-validated'
      }
      else {
        this.isWaiting = true
        const params = new URLSearchParams();
        params.append('action', 'createUser');
        params.append('email', this.email);
        params.append('password', this.password);
        axios.post(process.env.VUE_APP_API,params).then(response => {
          if (response.data.errors) {
            this.$store.commit('setAlert', {
              type: 'danger',
              messages: response.data.errors
            })
          }
          else {
            this.$store.commit('setAlert', {
              type: 'success',
              messages: ['Registrazione avvenuta con successo!', 'Ora puoi accedere con il form sottostante']
            })
            this.$emit('change', 'login')
          }
        }).catch(error => {
          console.error(error)
        }).finally(() => {
          this.isWaiting = false
          this.form_class = null
          
          this.email = null
          this.password = null
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  display: flex;
  align-items: center;
  .container {
    .row {
      h2 {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

section {
  margin: 0 auto;
  max-width: 400px;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 90%;
  }
    
  .form-content {
    background: $secondary-color;
    border-radius: 2rem;
    padding: 2rem;
    position: relative;

    h3 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    .form-group {
      margin-bottom: 1rem;
    }
    input.form-control, textarea.form-control {
      background: $secondary-color;
      border-color: lighten($tertiary-color, 40%);
    }
    .button-group {
      bottom: 0;
      left: 0;
      margin: 0;
      position: absolute;
      text-align: center;
      transform: translateY(50%);
      width: 100%;
      button, a.btn {
        background: $primary-color;
        border: none;
        font-size: 1.25rem;
        font-weight: bold;
        padding: .25rem 2rem;
      }
    }
  }
  .register {
    font-weight: bold;
    margin-top: 3rem;
    a {
      color: $primary-color;
    }
  }
}
</style>