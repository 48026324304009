<template>
  <div class="home">
    <div class="panel login" id="login">
      <div class="panel-bg">
        <header class="center">
          <logo></logo>
        </header>
        
        <login-form v-if="form == 'login'" @change="changeForm"></login-form>
        <registration-form v-else-if="form == 'registration'" @change="changeForm"></registration-form>
        <send-forgot-form v-else @change="changeForm"></send-forgot-form>

      </div>
    </div>
    <router-link to="about" class="go-down">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z"/></svg>
    </router-link>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
import RegistrationForm from '@/components/RegistrationForm.vue'
import SendForgotForm from '@/components/SendForgotForm.vue'
import Logo from '@/components/Logo.vue'

export default {
  name: 'Home',
  data() {
    return {
      form: 'login'
    }
  },
  components: {
    Logo,
    LoginForm,
    RegistrationForm,
    SendForgotForm
  },
  methods: {
    changeForm(form) {
      this.form = form
    }
  }
}
</script>


<style lang="scss" scoped>
.home {
  height: 100%;

  .panel {
    flex-direction: column;
    display: flex;
    height: 100%;
    position: relative;

    &#login {
      background: linear-gradient(180deg, $background-color 55%, white 40%);
      position: relative;

      .panel-bg {
        background: url('/imgs/login.png') no-repeat center top;
        background-size: auto 55%;
        display: flex;
        flex-direction: column;
        min-height: 100%;

        header {
          margin-bottom: auto;
          margin-top: 2rem;
          h1 {
            color: white;
          }
        }
      }      
    }
  }

  .go-down {
    bottom: 1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    svg {
      transform: rotate(90deg);
      fill: $primary-color;
    }
  }
}
</style>