import Vue from 'vue'
import VueCookie from 'vue-cookie'
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use(VueCookie)

export default new Vuex.Store({
  state: {
    alert: {
      type: null,
      messages: null
    },
    user: null,
    profile: null,
  },
  mutations: {
    setAlert (state, payload) {
      state.alert.type = payload.type
      state.alert.messages = payload.messages
    },
    removeAlert: (state) => {
      state.alert.type = null
      state.alert.messages = null
    },
    setUser: function (state, payload) {
      state.user = payload
      VueCookie.set('user', payload, 1)
    },
    setProfile: (state, payload) => {
      state.profile = payload
      localStorage.setItem('profile', JSON.stringify(payload))
    },
    logout: () => {
      VueCookie.delete('user')
      localStorage.setItem('profile', null)
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getAlert: state => state.alert,
    getUser: function() { return VueCookie.get('user') },
    getProfile: function() { return JSON.parse(localStorage.getItem('profile')) }
  }
})
