<template>
  <div id="app">
    <transition name="fade">
      <alert v-if="alert.type" :alert=alert @removeAlert=removeAlert></alert>
    </transition>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue';

export default {
  components: {
    Alert
  },
  computed: {
    alert () {
      return this.$store.getters.getAlert
    }
  },
  methods: {
    removeAlert () {
      this.$store.commit('removeAlert')
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');

html {
  height: 100%;
  overflow: hidden;
}
body {
  color: $tertiary-color!important;
  font-family: 'Josefin Sans', sans-serif;
  height: 100%;
  overflow: hidden;
}

#app {
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cm-toast {
  background: #99AB68;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.20);
  color: white;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 40px 60px;
  button {
    background: white;
    color: #99AB68;
    border: 0;
    margin: 10px 0 0;
    text-decoration: none;
  }

  @media screen and (max-width: 991px) {
    bottom: 0;
    width: 100%;
  }
}
</style>
