<template>

  <div class="alert alert-dismissible fade show" :class="'alert-' + this.alert.type" role="alert">
    <p v-for="message, key in this.alert.messages" :key="key">
      {{message}}
    </p>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="removeAlert"></button>
  </div>

</template>

<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true
    }
  },
  methods: {
    removeAlert () {
      this.$emit('removeAlert')
    }
  },
  mounted () {
    setTimeout(() => {
      this.removeAlert()
    }, 4000)
  }
}
</script>

<style lang="scss" scoped>
.alert {
  border: 0;
  border-radius: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 50%;
  z-index: 9999;
  p {
    margin: 1rem 0;
  }
  button {
    box-sizing: border-box;
    height: 100%;
    margin-right: 1rem;
  }
}
</style>