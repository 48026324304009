<template>
  <div id="login-form" class="content">
    <div class="container-fluid">
      <section>
        <div class="form-content">
          <form ref="loginForm" class="needs-validation" :class="form_class" novalidate @keyup.enter="submit">
            <h3 class="text-center">Trova la tua collaborazione</h3>
            <div class="form-group">
              <label for="email">Email *</label>
              <input :disabled="isWaiting == true" type="email" name="email" required id="email" v-model="email" class="form-control">
              <div class="invalid-feedback">
                Inserisci un'indirizzo email valido.
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password *</label>
              <input :disabled="isWaiting == true" type="password" name="password" required minlength="8" maxlength="100" id="password" v-model="password" class="form-control">
              <div class="invalid-feedback">
                Inserisci una password lunga almeno 8 caratteri.
              </div>
            </div>
            <div class="form-group button-group">
              <button :disabled="isWaiting == true" class="btn btn-primary" @click="submit">Accedi</button>
            </div>
          </form>
        </div>
        <div class="register text-center">
          <p>Non sei ancora registrato? <a @click="$emit('change', 'registration')">Clicca qui</a></p>
          <p><a @click="$emit('change', 'send-reset')">Password dimenticata?</a></p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data () {
    return {
      form_class: null,
      email: null,
      password: null,
      isWaiting: false,
    }
  },
  methods: {
    submit (evt) {
      evt.preventDefault();

      let form = this.$refs['loginForm']
      if (form.checkValidity() === false) {
        this.form_class = 'was-validated'
      }
      else {
        this.isWaiting = true
        axios.get(process.env.VUE_APP_API, {
          params: {
            action: 'login',
            email: this.email,
            password: this.password,
          }
        }).then(response => {
          if (response.data.errors) {
            this.$store.commit('setAlert', {
              type: 'danger',
              messages: response.data.errors
            })
          }
          else {
            this.$store.commit('setUser', response.data.data.uuid)
            this.$store.commit('setProfile', response.data.data)

            if (response.data.data.completed) {
              this.$router.push('finder')
            }
            else {
              this.$router.push('account')
            }
          }
        }).catch(error => {
          console.error(error)
        }).finally(() => {
          this.isWaiting = false
          this.form_class = null
          
          this.email = null
          this.password = null
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  display: flex;
  align-items: center;
  .container {
    .row {
      h2 {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

section {
  margin: 0 auto;
  max-width: 400px;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 90%;
  }
    
  .form-content {
    background: $secondary-color;
    border-radius: 2rem;
    padding: 2rem;
    position: relative;

    h3 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    .form-group {
      margin-bottom: 1rem;
    }
    input.form-control, textarea.form-control {
      background: $secondary-color;
      border-color: lighten($tertiary-color, 40%);
    }
    .button-group {
      bottom: 0;
      left: 0;
      margin: 0;
      position: absolute;
      text-align: center;
      transform: translateY(50%);
      width: 100%;
      button, a.btn {
        background: $primary-color;
        border: none;
        font-size: 1.25rem;
        font-weight: bold;
        padding: .25rem 2rem;
      }
    }
  }
  .register {
    font-weight: bold;
    margin-top: 3rem;
    p {
      margin-bottom: 5px;
    }
    a {
      color: $primary-color;
    }
  }
}
</style>